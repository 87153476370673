<!--
 * @Author: 钟澳鹏 1269372093@qq.com
 * @Date: 2023-05-17 12:08:30
 * @LastEditors: 钟澳鹏 1269372093@qq.com
 * @LastEditTime: 2023-09-15 08:55:25
 * @FilePath: \dalian\src\components\TabContent2.vue
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
-->
<template>
  <div class="tab-content2">
    <img src="../assets/banner3.png" class="banner">
    <div class="content">
      <GridTitle text="企业简介"></GridTitle>
      <div class="grid-1">
        <img src="../assets/tab4/1.png">
        <div>湖南鸿格信息技术有限公司注册成立于2021年12月,注册资金为5000万元人民币。公司主营业务为软件开发、信息电子技术服务、信息系统集成服务、技术服务、技术开发等。</div>
        <div>公司是国内领先的数字化技术与运营服务商，立足前沿科技专注技术开发数字化、企业服务数字化和企业经营数字化。公司自成立以来，一直秉持着稳健经营、持续创新的发展理念，致力于为客户提供优质、高效的产品和服务。公司拥有一支经验丰富的的软件研发团队，其中涵盖技术研发40人、高级JAVA 工程师20人，硕士15人等。熟练掌握了Java、Python、C++、JavaScript等多种编程语言。公司采用先进的软件开发方法论，能够为客户提供高质量的软件开发服务及平台服务。公司拥有强大的数据处理和分析能力，能够利用大数据技术对海量数据进行快速处理和可视化呈现，为客户提供更加精准的决策支持。</div>
        <div>公司除了为客户提供数字化技术服务平台，还为客户提供银行通道科技服务。目前公司与国内多家银行开展合作，支持通道多，接口种类全，可为消费金融公司、基金公司、小额贷款公司提供最合适的支付通道，提高交易成功率，有效节省客户自主对接时间。</div>
        <div>消费金融、基金公司（简称收款人）通过湖南鸿格信息技术有限公司搭建平台通道接入银行（收款机构）代收系统。银行按照收款人发起的还款/扣款指令，将用户账户资金划扣至收款人账户。收款人将代收资金转至银行账户。通过银行分账系统将资金分账清算至基金专用账户/还款本息至资方。</div>
      </div>
    </div>
  </div>
</template>

<script>
import GridTitle from './GridTitle.vue'
export default {
  name: 'TabContent4',
  components:{
    GridTitle
  }
}
</script>

<style scoped>
.banner{
  height: 651px;
  width: 100%;
 }
 .content{
  width: 1200px;
  margin: 72px auto 0 auto;
 }

 .grid-1{
    font-size: 22px;
    font-family: Alibaba PuHuiTi;
    font-weight: 500;
    color: #666666;
    line-height: 32px;
    padding: 67px 0 57px 0;
    text-align: justify;
 }
 .grid-1>div{
    padding: 19px 0;
 }
 .grid-1>img{
    height: 555px;
    width: 1015px;
    margin: 0 auto 40px auto;
    display: block;
 }
</style>
